#kv_root {
  position: fixed;
  top: 120px;
  right: 100px;
  z-index: 999999;
  display: flex;
  gap: 10px;
  margin: 0;
  font-family: "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#kv_root button {
  margin: 0;
  padding: 0;
  background-color: unset;
  border: unset;
  cursor: pointer;
}