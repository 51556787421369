.wrap {
	position: relative;

	&:hover .list {
		height: auto;
	}
}

.list {
	position: absolute;
	right: 0;
	display: flex;
	gap: 12px;
	height: 0;
	overflow: hidden;
}

.btn {
	width: max-content;
	padding: 4px;
	border-radius: 4px;
	border: 1px solid gray;
}